import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import logo from '../assets/img/ruizalu_logo.png';
import '../styles/header.scss';

const Header = ({ menu }) => {
  let menuElement = null;
  if (menu) {
    menuElement = (
      <nav className="header_nav">
        <ul>
          <li><Link to="#portfolio">Portfolio</Link></li>
          <li><Link to="#entreprise">L'entreprise</Link></li>
          <li><Link to="#partenaires">Partenaires</Link></li>
          <li><Link to="#contact">Contact</Link></li>
        </ul>
      </nav>
    );
  }
  return (
    <header>
      <div className="header_header">
        <Link to="/"><img src={logo} alt="logo ruizalu" /></Link>
        <span>
        Étude et mise en oeuvre en menuiserie |
          <span className="header_phone"> 04 94 75 26 82</span>
        </span>
      </div>
      {menuElement}
    </header>

  );
};

Header.defaultProps = {
  menu: true,
};

Header.propTypes = {
  menu: PropTypes.bool,
};

export default Header;
