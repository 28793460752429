import React from 'react';
import '../styles/index.scss';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import Footer from './footer';
import Header from './header';
import favicon from '../assets/img/favicon.png';

const Layout = ({ children, menu }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <div>
        <Helmet
          title="Ruiz Aluminium - Mise en œuvre, fourniture et pose de menuiseries"
          meta={[
            {
              name: 'description',
              content: 'Ruiz aluminium, société spécialisé dans l’étude de la mise en œuvre, la fourniture et la pose de menuiseries Aluminium et PVC',
            },
          ]}
        >
          <link rel="icon" type="image/png" href={favicon} />
          <html lang="fr" />
        </Helmet>
        <Header menu={menu} />
        {children}
        <Footer />
      </div>
    )}
  />
);

Layout.defaultProps = {
  menu: true,
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  menu: PropTypes.bool,
};

export default Layout;
