import React from 'react';
import { Link } from 'gatsby';
import '../styles/footer.scss';
import logoLight from '../assets/img/ruizalu_logo_light.png';
import logoQualibat from '../assets/img/footer_qualibat_logo.png';

const Footer = props => (
  <footer id="contact">
    <div className="footer_header">
      <img src={logoLight} alt="logo ruizalu clair" />
      <span>Étude et mise en oeuvre en menuiserie</span>
    </div>
    <div className="footer_content">
      <nav className="footer_nav">
        <ul>
          <li><Link to="#portfolio">Portfolio</Link></li>
          <li><Link to="#entreprise">L'entreprise</Link></li>
          <li><Link to="#partenaires">Partenaires</Link></li>
          <li><Link to="#contact">Contact</Link></li>
        </ul>
      </nav>
      <div className="footer_infos">
        <div className="footer_map">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2911.644264961405!2d6.068691015480929!3d43.132998679142545!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x405a3247c720c4ad%3A0x5183ea78a91b1a9b!2sRuiz+Aluminium!5e0!3m2!1sfr!2sfr!4v1538475202997" frameBorder="0" allowFullScreen title="map ruizalu" />
        </div>
        <div className="footer_contactinfos_container">
          <img src={logoQualibat} alt="qualibat logo" />
          <div className="footer_contactinfos">
            <address>RUIZALU - ZAC de GAVARY - 66 Rue Ampère - 83260 La CRAU</address>
            <a href="tel:0494752682">04 94 75 26 82</a>
          </div>
        </div>
      </div>
    </div>
    <div className="footer_footer">
      <a href="https://comdeslezards.com">@Comdeslezards 2018</a>
    </div>
  </footer>

);

export default Footer;
